/* Import Tailwind CSS */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://use.typekit.net/fkg4vuo.css');

/* Custom Styles */
:root {
  font-family: 'calmetta', sans-serif;
}

/* Logo styles */
.logo {
  margin: auto;
  margin-top: 2rem;
  display: block;
}

/* Payoff styles */
.payoff {
  font-size: 1.875rem; /* equivalent to text-3xl */
  font-weight: bold;
  margin-top: 1rem;
}

/* Button styles */
.button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.button:hover {
  background-color: #2c0b7f; /* darker shade of blue on hover */
}

/* Footer styles */
.footer {
  text-align: center;
  padding: 1rem 0;
}

.content h2 {
  font-size: 1.563rem;
  font-weight: bold;
  margin:20px 0;
}
.content p {
  margin: 10px 0px;
}
.content ul {
  margin: 10px 0px;
}
.content li {
  margin-left: 30px;
  padding-left: 10px;
  list-style-type:disc;
}

/* Apply margin to all cells */
.content table {
  border-collapse: collapse;
  margin:20px;
  margin: 0 auto;
}

.content table td, table th {
  padding: 5px;
}

.content table tr:not(:first-child) {
  border-top: 1px dashed #000;
}

.content table td:not(:first-child) {
  border-left: 1px dashed #000;
}

/* Optional: Center align text */
.content table td, table th {
  text-align: center;
}

.arrow {
  width: 0; 
  height: 0; 
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #ED1C25;
  transform: rotate(45deg);
  margin-right:-32px;
  margin-top:-10px;
}

.contentBackground {
  clip-path: polygon(0 0, 100% 100px, 100% 100%, 0 100%);
}

.beveledBottom {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 30px));
}

.beveledTop {
  clip-path: polygon(0 0, 100% 30px, 100% 100%, 0 100%);
}

.beveledContent {
  clip-path: polygon(0 0, 100% 20px, 100% 100%, 0 calc(100% - 20px));
}
